@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

$family-sans-serif: 'Open Sans', sans-serif;
$primary: #00B6ED;

@import '~bulma/bulma';
@import "~bulma-pageloader";

body {
  color: #444444;
  font-size: 14px;
  @include tablet {
    font-size: 16px; } }

.booking-widget {
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-out;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
  margin: 0;
  overflow: hidden;

  @include tablet {
    flex-direction: row;
    margin: 2em auto;
    width: 750px;
    border: 1px solid rgba(77, 80, 85, 0.1);
    border-radius: 8px; } }


.date-time-picker__container {
  margin: 0 auto;
  h3 {
    margin-bottom: 0.5em;
    font-weight: 700;
    font-size: 1.25em; }
  &>h3 {
    display: none; }
  .time-picker {
    display: none; }
  .date-picker {
    margin: 0 auto; }

  @include tablet {
    margin: 1em;
    &>h3 {
      display: unset; }
    .time-picker,
    .date-picker {
      width: 312px;
      h3 {
        display: none; } } } }


.date-time-picker {
  display: flex; }

.expand {
  .date-picker {
    display: none; }
  .time-picker {
    display: unset; }
  @include tablet {
    .date-picker,
    .time-picker {
      display: flex; }
    width: 970px; } }


.booking-info {
  width: 100%;
  flex-shrink: 0;

  @include tablet {
    border-right: 1px solid rgba(77, 80, 85, 0.1);
    //margin-right: 16px
    width: 400px; }
  &.extended {
    width: 100% !important; }

  address {
    display: inline-block; }
  &__header {
    padding: 0; }

  .logo {
    background-color: #00B6ED;
    height: 86px; }

  svg {
    margin-right: 0.25em; }

  &__content,
  &__footer {
    padding: 1em;
    border-bottom: 1px solid rgba(77, 80, 85, 0.1);
    border-top: none;
    @include tablet {
      border-top: 1px solid rgba(77, 80, 85, 0.1);
      border-bottom: none; }

    h1 {
      font-size: 1.5em;
      font-weight: bold;
      @include tablet {
        font-size: 2em; } }
    &>div {
      color: lightslategray;
      margin: 0.75em 0;
      font-size: 1em;
      font-weight: 600; } }

  .info {
    margin-top: 1em;
    color: #444444;
    font-size: 1em;
    font-weight: normal; }

  .slot {
    color: forestgreen; } }


.booking-form {
  margin: 1em;
  max-width: 500px;
  width: 100%; }

.booking-message {
  width: 100%;
  h1 {
    font-size: 1.5em;
    font-weight: bold;
    @include tablet {
      font-size: 2em; } }
  &>p {
    margin-top: 1em;
    color: #444444;
    font-size: 1em;
    font-weight: normal; } }


.select-time-item {
  display: flex;
  width: 220px;
  overflow: hidden; }

.select-time, .confirm-time {
  border-radius: 10px;
  -webkit-transition: width 0.5s, border-radius 0.5s, opacity 0.5s;
  transition: width 0.5s, border-radius 0.5s, opacity 0.5s; }

.select-time {
  width: 170px;
  &.is-expanded {
    width: 220px; } }

.confirm-time {
  width: 50px;
  opacity: 0;
  &.is-expanded {
    opacity: 1; } }

/* DateTimePicker styles */

/* DayPicker styles */

.DayPicker {
  display: inline-block;
  width: auto;

  &-wrapper {
    position: relative;
    flex-direction: row;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

  &-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: left; }

  &-Month {
    display: table;
    margin: 0;
    border-spacing: 0.25em;
    border-collapse: separate;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

  &-NavButton {
    position: absolute;
    top: 0.5em;
    right: 0em;
    left: auto;

    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8B9898;
    cursor: pointer;
    &:hover {
      opacity: 0.8; }

    &--prev {
      margin-right: 1.5em;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC'); }

    &--next {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg=='); }

    &--interactionDisabled {
      display: none; } } }

.DayPicker-Caption {
  display: table-caption;
  text-align: left; }

.DayPicker-Caption > div,
.TimePicker-Caption > div {
  margin-bottom: 0.5em;
  font-weight: 500;
  font-size: 1.15em; }

.DayPicker-Weekdays {
  display: table-header-group; }

.DayPicker-WeekdaysRow {
  display: table-row; }

.DayPicker-Weekday {
  display: table-cell;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em; }

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none; }

.DayPicker-Body {
  display: table-row-group; }

.DayPicker-Week {
  display: table-row; }

.DayPicker-Day {
  color: #8B9898;
  font-weight: 300;
  width: 2.25em;
  height: 2.25em;
  display: table-cell;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  @include tablet {
    width: 2.5em;
    height: 2.5em; } }

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer; }


.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default; }


.DayPicker-Footer {
  padding-top: 0.5em; }


.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer; }


/* Default modifiers */

.DayPicker-Day--today {}


.DayPicker-Day--outside {
  background-color: #fff !important;
  cursor: default; }


.DayPicker-Day--disabled {
  color: #ccc;
  cursor: default; }
/* background-color: #eff1f1 */


/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8; }


.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0; }


.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #4A90E2;
  color: #F0F8FF; }


.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA; }


.DayPicker:not(.DayPicker--interactionDisabled) {
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {}
  background-color: #fff; }


/* DayPickerInput */

.DayPickerInput {
  display: inline-block; }


.DayPickerInput-OverlayWrapper {
  position: relative; }


.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); }

.DayPicker {}
.DayPicker-wrapper,
.DayPicker-Day,
.DayPicker-NavButton {
  &:focus {
    outline: none; } }

.DayPicker-Day--available {
  background: #b2c4ce;
  color: #444;
  font-weight: normal; }



